:root {
  --default-theme-color: #ffc700;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.bg-img {
  background-image: url("./assets/img/background.jpg");
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
  background-size: cover;
  filter: blur(5px);
}

.header-warrper {
  display: flex;
  align-items: center;
  margin: auto;
  width: 100%;
  height: 6vh;
  max-width: 360px;
  background-color: black;
  border-right: solid white 1px;
  border-left: solid white 1px;
}
.page-wrapper {
  margin: auto;
  width: 100%;
  max-width: 360px;
  height: 88vh;
  /* background-image: url(/assets/images/homebg.png); */
  background-size: cover;
  overflow-x: hidden;
  position: relative;
  border-right: solid white 1px;
  border-left: solid white 1px;
  background-color: black;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  display: flex;
  color: white;
  flex-direction: column;
}
::-webkit-scrollbar {
  display: none;
}

.native-ads-size {
  max-width: 320px;
  max-height: 245px;
  margin: auto;
}
.question-answer-box {
  background-color: #1f1f1f;
  width: 90%;
  height: auto;
  border-radius: 25px;
  padding-bottom: 20px;
}
.options {
  height: 50px;
  border: 1px solid #4b4b4b;
  margin: auto;
  margin-top: 5px;
  border-radius: 11px;
  text-align: left !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
  margin: 5px;
}
.quiz-text {
  color: var(--default-theme-color);
}
.quiz-btn {
  background-color: var(--default-theme-color);
  width: 80%;
  height: 60px;
  border: none;
  border-radius: 15px;
  text-align: center;
  padding-left: 20px;
  font-size: 18px;
  font-weight: 700;
  background-repeat: no-repeat;
  background-position: right 24px center;
}
ul li {
  font-size: 14px;
  list-style: "👉";
  padding: 8px 0px;
  width: 100%;
}
.categorys li {
  font-size: 12px;
  list-style: none !important;
}
.active-menu {
  background-color: var(--default-theme-color);
  border-radius: 10px;
  color: #000000;
  font-weight: 500;
}
.sticky-navbar {
  position: fixed;
  bottom: 0;
  display: flex;
  margin: auto;
  max-width: 358px;
  width: 100%;
  background-color: #000000;
  padding: 10px;
  text-align: center;
  color: white;
  z-index: 1000;
  height: 70px;
}
.owl-theme .item {
  text-align: center;
}
.owl-theme .owl-nav {
  position: absolute;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  top: -30px;
}
.owl-theme {
  position: relative;
  z-index: 10;
}
.owl-next {
  position: relative;
  right: -27px;
}
.owl-prev {
  position: relative;
  left: -27px;
}
.owl-stage-outer {
  position: relative;
  z-index: 999;
}
.owl-theme .owl-nav [class*="owl-"] {
  font-size: 35px !important;
}
.owl-theme .owl-nav [class*="owl-"]:hover {
  background-color: transparent !important;
}
.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgb(220 53 69 / 14%) !important;
  border: 2px solid rgb(220 53 69);
}
.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgb(25 135 84 / 14%) !important;
  border: 2px solid rgb(25 135 84);
}
.category-tiles {
  background-color: #000000;
  border-radius: 13px;
  padding: 4px;
}
.cat-block {
  background: #1e1e1e;
  border-radius: 20px;
}
.rounded-50 {
  border-radius: 50%;
}
.category-name {
  font-size: 10px;
}
.entry-btn {
  background-color: var(--default-theme-color);
  width: 70%;
  height: 25px;
  border: none;
  border-radius: 20px;
  font-size: 9px;
  font-weight: 700;
}
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.search {
  background-color: transparent;
  border: 1px solid var(--default-theme-color);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
}
.input-hide {
  width: 100%;
  background-color: transparent;
  border: none;
  color: white;
  margin-right: 10px;
}
.input-hide:focus-visible {
  outline: none;
}
.cursor-pointer {
  cursor: pointer;
}
.wallet {
  height: auto;
  width: 100%;
  border: 2px solid #ffc700;
  border-radius: 15px;
  background-color: transparent;
  color: white;
  text-align: start;
  padding: 10px;
  font-size: 15px;
}
.quiz-played {
  height: auto;
  width: 100%;
  border: 2px solid white;
  border-radius: 15px;
  background-color: transparent;
  color: white;
  text-align: start;
  padding: 10px;
  font-size: 15px;
}
.quiz-entry-box {
  height: auto;
  width: 90%;
  background: linear-gradient(#000000, #000000) padding-box,
    linear-gradient(to top, rgb(0, 0, 0), #ca9f02) border-box;
  border-radius: 15px;
  border: 2px solid transparent;
  margin: auto;
}
.modal-content {
  background-color: #000000;
  color: white;
}
.btn-close {
  background-color: white;
}
.item-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.signup-btn {
  background-color: var(--default-theme-color);
  width: 60%;
  height: 44px;
  border: none;
  border-radius: 15px;
  text-align: center;
  /* padding-left: 20px; */
  font-size: 14px;
  font-weight: 700;
  background-repeat: no-repeat;
  background-position: right 24px center;
}

.close-button {
  text-align: right;
  padding-right: 21px;
  font-size: 30px;
  margin-left: auto;
  cursor: pointer;
}
@media only screen and (max-width: 576px) {
  .page-wrapper {
    max-width: 576px;
  }
  .sticky-navbar {
    max-width: 576px;
    width: 99%;
  }
  .header-warrper {
    max-width: 576px;
  }
}
